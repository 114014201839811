import ListContentLayout from 'layout/ListContentLayout'
import React, { FC, useEffect, useMemo } from 'react'
import { PropertyList } from 'components/shared/Property'
import useFetchListing from 'hooks/useFetchListing'
import ProListingActionBlock from '../components/ProListingActionBlock'
import ProListingHeader from '../components/ProListingHeader'
import { TPropertyListing } from '../types'
import { useProListing } from '../provider/ProListingProvider'

const BrokerageListing: FC = () => {
 const { selectedBroker, sort } = useProListing()

 const { flatData, isLoading, fetchMore, hasMore } =
  useFetchListing<TPropertyListing>('get-brockerage-prop-list', {
   params: `sort_by=${sort ?? '0'}&brokerage_list=${JSON.stringify(
    selectedBroker ?? null,
   )}`,
  })
 return (
  <ListContentLayout
   actions={<ProListingActionBlock />}
   title="Brokerage Listings"
  >
   <div className="prolisting-page">
    <ProListingHeader type="brokerage" />
    <PropertyList
     data={flatData}
     fetchMore={fetchMore}
     hasMore={hasMore}
     isLoading={isLoading}
     selection
     isBrokerage
    />
   </div>
  </ListContentLayout>
 )
}

export default BrokerageListing
