import { Modal, Select } from 'antd'
import { useAssignAlertBulkMutation } from 'features/alert'
import {
 useAssignBulkNewsLetterMutation,
 useAssignToActionPlanBulkMutation,
} from 'features/automation'
import { TSelectAllP } from 'features/leads'
import { useAddRealtorSourceMutation } from 'features/realtors'
import { useGetGroupsQuery } from 'features/settings'
import { FC, useState } from 'react'

type AddTagsProp = {
 type?: 'actionPlan' | 'realtors' | 'alerts'
 selectedKeys?: any[]
 visible: boolean
 onCancel: () => void
 refetch?: () => void
 extraBody?: any
}

const AssignToSourceModal: FC<TSelectAllP & AddTagsProp> = ({
 type = 'lead',
 visible,
 onCancel,
 selectedKeys,
 refetch,
 selectAll,
 unSelected,
 filterInfo,
 extraBody,
}) => {
 const { data } = useGetGroupsQuery({ type: ['source'] })
 const [assignToActionPlanBulk, { isLoading: actionPlanLoading }] =
  useAssignToActionPlanBulkMutation()
 const [addRealtorSource, { isLoading: realtorSourceLoading }] =
  useAddRealtorSourceMutation()
 const [assingnNewsLetterBulk, { isLoading: newsLetterLoading }] =
  useAssignBulkNewsLetterMutation()
 const [selectedSource, setSource] = useState<any>([])
 const [assignAlertSource, { isLoading: alertLoading }] =
  useAssignAlertBulkMutation()

 const handleSubmit = async (): Promise<void> => {
  const body: any = {}
  if (selectAll) {
   body.select_all = selectAll
   body.unselected = unSelected
   body.filter_info = filterInfo
  }
  if (type === 'Real-Estate-News-Letter' || type === 'market-update') {
   await assingnNewsLetterBulk({
    ...body,
    type_id: selectedSource,
    newsletter_type: type,
    selectall_type: 'source',
    ...extraBody,
   })
  } else if (type === 'actionPlan') {
   await assignToActionPlanBulk({
    ...body,
    actionplan_id: selectedKeys,
    type: 'source',
    type_id: selectedSource,
   }).unwrap()
  } else if (type === 'realtors') {
   await addRealtorSource({
    ...body,
    realtor_id: selectedKeys,
    source_id: selectedSource,
   }).unwrap()
  } else if (type === 'alert') {
   await assignAlertSource({
    ...body,
    type: 'source',
    alert_id: selectAll ? [] : selectedKeys,
    type_id: selectedSource,
   }).unwrap()
  }
  refetch?.()
  handleClose?.()
 }
 const handleClose = () => {
  onCancel?.()
  setSource(undefined)
 }

 return (
  <Modal
   forceRender
   confirmLoading={
    actionPlanLoading ||
    realtorSourceLoading ||
    newsLetterLoading ||
    alertLoading
   }
   okButtonProps={{ disabled: !selectedSource }}
   onOk={handleSubmit}
   okText="Submit"
   onCancel={handleClose}
   visible={visible}
   title="Assign to source"
  >
   <Select
    placeholder="Select Source"
    value={selectedSource}
    onChange={(e) => setSource(e)}
    className="w-100"
    mode="multiple"
    options={data?.source?.map((v) => ({ label: v.name, value: v.id }))}
    filterOption={(input, option) =>
     option?.label
      ? option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : false
    }
   />
  </Modal>
 )
}

export default AssignToSourceModal
