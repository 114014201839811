/* eslint-disable no-nested-ternary */
import React, {
 Dispatch,
 SetStateAction,
 useContext,
 useEffect,
 useState,
} from 'react'
import { FCC, NewListingSlug, TModalState } from 'types'
import { useGlobal } from 'raducer/global'
import { TProFilterState, TPropertyListing, TSearchTypes } from '../types'
// import { FilterType } from '../routes/List'

type TProListing = {
 type: 'preconstruction' | 'listing'
 selectedPro: TPropertyListing[]
 setSelectedPro: Dispatch<SetStateAction<TPropertyListing[]>>
 selectedBroker: any[] | null
 setSelectedBroker: Dispatch<SetStateAction<any[] | null>>
 sort: '0' | '1' | '2'
 setSort: Dispatch<SetStateAction<'0' | '1' | '2'>>
 filterState: TProFilterState
 setFilterState: Dispatch<SetStateAction<TProFilterState>>
 filterParams: string
 setFilterParams: Dispatch<SetStateAction<string>>
 changeListingType: (val: NewListingSlug) => void
 search: string
 setSearch: Dispatch<SetStateAction<string>>
 searchType: TSearchTypes | null
 setSearchType: Dispatch<SetStateAction<TSearchTypes | null>>
 selectedCity: string
 setSelectedCity: Dispatch<SetStateAction<string>>
}

type TFilterKeys =
 //  | 'listing_type'
 | 'max_price'
 | 'min_price'
 | 'property_type'
 | 'building_type'
 | 'construction_status'
 | 'completion_year'
 | 'ownership'
 | 'min_sqft'
 | 'max_sqft'
 | 'beds'
 | 'baths'

export const proFilterIntialState: TProFilterState = {
 listing_type: 'sale',
 max_price: '',
 min_price: '',
}
export const ProListingContext = React.createContext<TProListing>({
 type: 'listing',
 selectedPro: [],
 setSelectedPro: () => null,
 selectedBroker: [],
 setSelectedBroker: () => null,
 sort: '0',
 setSort: () => null,
 filterState: proFilterIntialState,
 setFilterState: () => null,
 filterParams: '',
 setFilterParams: () => null,
 changeListingType: () => null,
 search: '',
 setSearch: () => null,
 searchType: null,
 setSearchType: () => null,
 selectedCity: '',
 setSelectedCity: () => null,
})

const ProListingProvider: FCC<{
 from: 'preconstruction' | 'listing'
}> = ({ children, from }) => {
 const { Provider } = ProListingContext
 const { global } = useGlobal()
 const [filterState, setFilterState] =
  useState<TProFilterState>(proFilterIntialState)
 const [filterParams, setFilterParams] = useState<string>('')
 const [selectedPro, setSelectedPro] = useState<TPropertyListing[]>([])
 const [selectedBroker, setSelectedBroker] = useState<any[] | null>(
  global.brokerage_data?.[0] ? [global.brokerage_data?.[0]] : null,
 )
 const [selectedCity, setSelectedCity] = useState('')
 const [sort, setSort] = useState<'0' | '1' | '2'>('0')
 const [search, setSearch] = useState('')
 const [searchType, setSearchType] = useState<TSearchTypes | null>(null)
 useEffect(() => {
  const { listing_type, ...loopState } = filterState
  setFilterParams(
   `sort_by=${sort ?? '0'}&search=${
    ['city', 'community'].includes(searchType as any)
     ? selectedCity
     : search ?? null
   }&search_type=${searchType ?? null}${
    from !== 'preconstruction' ? `&listing_type=${listing_type}` : ''
   }${
    global?.neighbourhood
     ? `&${from === 'listing' ? 'nh' : 'nb'}=${JSON.stringify(
        global?.neighbourhood,
       )}`
     : ''
   }${Object.keys(loopState)
    ?.map((i, n) =>
     loopState[i as TFilterKeys]
      ? `&${i}=${
         (i === 'property_type' && loopState[i]?.length !== 0) ||
         (i === 'building_type' && loopState[i]?.length !== 0)
          ? from === 'listing'
            ? JSON.stringify(loopState[i])
            : loopState[i as TFilterKeys]
          : loopState[i as TFilterKeys]
        }`
      : '',
    )
    .join('')}`,
  )
 }, [filterState, selectedBroker, sort, searchType])

 const onClear = () => {
  setSelectedPro([])
  setSort('0')
  setSearch('')
  setSearchType(null)
  setSelectedCity('')
 }

 useEffect(() => {
  setFilterState(proFilterIntialState)
  setSelectedBroker(
   global.brokerage_data?.[0] ? [global.brokerage_data?.[0]] : null,
  )
  onClear()
 }, [from])

 const changeListingType = (val: NewListingSlug) => {
  setFilterState({ ...proFilterIntialState, listing_type: val })
  onClear()
 }

 return (
  <Provider
   value={{
    type: from,
    selectedPro,
    setSelectedPro,
    sort,
    setSort,
    selectedBroker,
    setSelectedBroker,
    filterParams,
    setFilterParams,
    filterState,
    setFilterState,
    changeListingType,
    search,
    searchType,
    setSearch,
    setSearchType,
    selectedCity,
    setSelectedCity,
   }}
  >
   {children}
  </Provider>
 )
}

export default ProListingProvider

export const useProListing = () => useContext(ProListingContext)
