import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import AlertList from '../components/AlertList'

export const AlertRoutes: FC = () => {
 return (
  <Routes>
   <Route path="" element={<Navigate to="new-listing" />} />
   <Route path="new-listing" element={<AlertList type="forsale" />} />
   <Route path="sold-listing" element={<AlertList type="sold" />} />
   <Route path="open-house-listing" element={<AlertList type="openhouse" />} />
  </Routes>
 )
}
