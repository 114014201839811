import useFetchPage from 'hooks/useFetchPage'
import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useState } from 'react'
import ControlActionPlanModal from 'components/shared/Modals/ControlActionPlanModal'
import { Modal } from 'antd'
import { MDataTable } from 'components/shared/MDataTable'
import useSelectAll from 'hooks/useSelectAll'
import { queryClient } from 'lib/react-query'
import BuilkActions from '../../components/action-plan/BuilkActions'
import TableColumns from '../../components/action-plan/TableColumns'
import { TActionPlanList } from '../../types'
import Add from './Add'
import View from './View'
import Edit from './Edit'

export type TControlAPModal = {
 type: 'Pause' | 'Stop' | 'Completed' | 'Running' | null
 show: boolean
 id: any
}

const List: FC = () => {
 const [searchKey, setSearchKey] = useState('')
 const [showModal, setShowModal] = useState<TControlAPModal>({
  type: null,
  show: false,
  id: null,
 })
 const [apActionModal, setApActionModal] = useState<{
  show: 'view' | 'edit' | 'add' | false
  id: null | number
 }>({ id: null, show: false })
 const {
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  flatData,
  totalCount,
  isRefetching,
 } = useFetchPage<TActionPlanList>('get-action-plans', {
  params: searchKey ? `search=${searchKey}` : '',
  customApiKey: `get-action-plans-${searchKey}`,
 })

 const {
  selectedKeys,
  onSelectRow,
  rowSelectionState,
  selectedRows,
  selectAll,
  unSelected,
  setSelectedRow,
 } = useSelectAll(JSON.stringify({ search: searchKey }))

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])

 const commonModalProps = {
  onCancel: () => setApActionModal({ show: false, id: null }),
  visible: true,
  width: '100vw',
  centered: true,
  className: 'page-size-modal',
 }

 return (
  <>
   <ListContentLayout
    title="Action Plans"
    actions={
     <BuilkActions
      onClickAdd={() => setApActionModal({ show: 'add', id: null })}
      actionRefresh={refetch}
      selectedRows={selectedRows}
      selectAll={selectAll}
      unSelected={unSelected}
      filterInfo={JSON.stringify({ search: searchKey })}
      mode="action-plan"
     />
    }
   >
    <MDataTable
     totalData={totalCount || 1000}
     search={{
      placeholder: 'Enter Action Plan Name',
      onSearch: setSearchKey,
      show: true,
     }}
     onSelectRow={onSelectRow}
     rowSelectionState={{
      ...rowSelectionState,
     }}
     loading={isLoading}
     isFetching={isRefetching}
     hasMore={hasMore}
     fetchMore={() => {
      fetchMore()
     }}
     data={flatData}
     columns={TableColumns({
      selectedKeys,
      selectAll,
      unSelected,
      handleClickEdit: (id?: any) => setApActionModal({ id, show: 'edit' }),
      handleClickView: (id?: any) => setApActionModal({ id, show: 'view' }),
      //   clickActiveModal: (id?: any) =>
      //    setShowModal({ id, show: true, type: 'Running' }),
      handleControlAP: (
       type: 'Pause' | 'Stop' | 'Completed' | 'Running',
       id?: any,
      ) => {
       queryClient.removeQueries({
        queryKey: `get-action-plan-leads-${id}-${type}-`,
        exact: true,
       })
       setShowModal({ id, type, show: true })
      },
      mode: 'action-plan',
     })}
    />
    {showModal?.show && (
     <ControlActionPlanModal
      ctrlAP={showModal}
      onCancel={() => setShowModal({ show: false, type: null, id: null })}
      refetch={refetch}
      from="action-plan"
     />
    )}
   </ListContentLayout>
   {apActionModal?.show === 'add' && (
    <Modal title="Add Action Plan" {...commonModalProps}>
     <Add onCancel={() => setApActionModal({ show: false, id: null })} />
    </Modal>
   )}
   {apActionModal?.show === 'view' && (
    <Modal title="Action Plan" {...commonModalProps}>
     <View id={`${apActionModal?.id}`} />
    </Modal>
   )}
   {apActionModal?.show === 'edit' && (
    <Modal title="Edit Action Plan" {...commonModalProps}>
     <Edit
      id={`${apActionModal?.id}`}
      onCancel={() => setApActionModal({ show: false, id: null })}
     />
    </Modal>
   )}
  </>
 )
}

export default List
