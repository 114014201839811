import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import Navbar from 'components/layout-modules/Navbar/Navbar'
import Sidebar from 'components/layout-modules/Sidebar/Sidebar'
import { NoticationModule } from 'components/layout-modules/NoticationModule'
import { useGlobal } from 'raducer/global'
import { FCC } from 'types'
// import SidebarLinks from 'static-data/SidebarLinks'
import { useProfileQuery } from 'features/auth'
import useAuth from 'hooks/useAuth'
import { useGlobalSettingsQuery } from 'api/baseApi'
import { FullLoader } from 'components/elements/Loader'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import {
 MyBrokerageIcon,
 SvgAlertIcon,
 SvgAutomationIcon,
 SvgCrmIcon,
 SvgNewListingIcon,
 SvgSettingsIcon,
} from 'components/icons'
import { APP_PREFIX_PATH } from 'config'
import OverlayContextWrapper from './OverlayContextWrapper'
import Footer from './Footer'
import AdminControlPanel from './AdminControlPanel'

const { Content } = Layout

const AdminLayout: FCC = () => {
 const { global, toggleNotification } = useGlobal()
 const { user } = useAuth()

 const { isLoading } = useProfileQuery()
 const { isLoading: globalLoading } = useGlobalSettingsQuery()

 if (isLoading || globalLoading) {
  return <FullLoader />
 }

 //  const ifListingKeyNull = (sidebar: any) => {
 //   if (global?.listing_key === null) {
 //    return sidebar
 //     .filter((item: any) => item?.label !== 'Listings')
 //     .map((it: any) => {
 //      if (it?.children) {
 //       it.children = it?.children?.filter?.(
 //        (child: any) => child.label !== 'Listing Settings',
 //       )
 //      }
 //      return it
 //     })
 //   }
 //   if (global?.listing_plan === 'Pre-Condo') {
 //    return sidebar.map((item: any) => {
 //     if (item?.label === 'Listings') {
 //      item.children = item?.children?.filter?.(
 //       (child: any) => child.label !== 'Property Listings',
 //      )
 //     }
 //     return item
 //    })
 //   }
 //   return sidebar
 //  }

 //  const withoutNeighbourhoodAlert = (sidebar: any) => {
 //   if (!global?.user_type_sold) {
 //    const updatedArray = sidebar.map((item: any) => {
 //     if (item?.label === 'Alert' && item?.children) {
 //      const filteredChildrens = item?.children?.filter(
 //       (child: any) => child?.label !== 'Neighbourhood Alert',
 //      )
 //      return { ...item, children: filteredChildrens }
 //     }
 //     return item
 //    })
 //    return updatedArray
 //   }
 //   return sidebar
 //  }

 //  const handleSideBar = () => {
 //   const { account_type } = user || {}
 //   const { user_type_sold, listing_plan } = global || {}
 //   let sideBar =
 //    listing_plan === 'Pre-Condo'
 //     ? SidebarLinks?.filter((i: any) => i?.label !== 'Alert')
 //     : SidebarLinks

 //   if (account_type === 'Brokerage' || account_type === 'Realtor') {
 //    sideBar = ifListingKeyNull(
 //     sideBar?.filter(
 //      (item: any) =>
 //       item?.label !==
 //       (account_type === 'Brokerage' ? 'My Brokerage' : 'My Realtors'),
 //     ),
 //    )
 //   }
 //   return !user_type_sold ? withoutNeighbourhoodAlert(sideBar) : sideBar
 //  }

 const { account_type } = user || {}
 const {
  user_type_sold,
  listing_plan,
  listing_key,
  brokerage_data,
  open_house,
 } = global || {}

 type SidebarLinksType = ItemType

 const SidebarLinks: SidebarLinksType[] = [
  {
   icon: <i className="fas fa-chart-line" />,
   key: `${APP_PREFIX_PATH}/dashboard`,
   label: 'Dashboard',
  },
  {
   icon: (
    <span>
     <SvgCrmIcon />
    </span>
   ),
   key: `${APP_PREFIX_PATH}/leads`,
   label: 'CRM',
  },
  ...(listing_key
   ? [
      {
       icon: (
        <span>
         <SvgNewListingIcon />
        </span>
       ),
       key: `${APP_PREFIX_PATH}/listing`,
       label: 'Listings',
       children: [
        ...(listing_plan !== 'Pre-Condo'
         ? [
            {
             label: 'Property Listings',
             key: `${APP_PREFIX_PATH}/listing/property-listing`,
            },
           ]
         : []),
        {
         label: 'Pre-Construction Listings',
         key: `${APP_PREFIX_PATH}/listing/pre-con-listing`,
        },
        ...((brokerage_data?.length ?? 0) > 0
         ? [
            {
             label: 'Brokerage Listings',
             key: `${APP_PREFIX_PATH}/listing/brokerage-listing`,
            },
           ]
         : []),
       ],
      },
     ]
   : []),
  ...(listing_plan !== 'Pre-Condo'
   ? [
      {
       icon: (
        <span>
         <SvgAlertIcon />
        </span>
       ),
       key: `${APP_PREFIX_PATH}/alert`,
       label: 'Alert',
       children: [
        {
         label: ' New Listing Alert',
         key: `${APP_PREFIX_PATH}/alert/new-listing`,
        },
        ...(user_type_sold
         ? [
            {
             label: 'Neighbourhood Alert',
             key: `${APP_PREFIX_PATH}/alert/sold-listing`,
            },
           ]
         : []),
        ...(open_house
         ? [
            {
             label: 'Open House Alert',
             key: `${APP_PREFIX_PATH}/alert/open-house-listing`,
            },
           ]
         : []),
       ],
      },
     ]
   : []),
  {
   icon: (
    <span>
     <SvgAutomationIcon />
    </span>
   ),
   key: `${APP_PREFIX_PATH}/automation`,
   label: 'Automation',
   children: [
    {
     label: 'Action Plan',
     key: `${APP_PREFIX_PATH}/automation/action-plan`,
    },
    {
     label: 'Market Updates',
     key: `${APP_PREFIX_PATH}/automation/market-updates`,
    },
    {
     label: 'Real Estate Newsletter',
     key: `${APP_PREFIX_PATH}/automation/real-estate-news`,
    },
   ],
  },
  ...(account_type === 'Brokerage'
   ? [
      {
       icon: <i className="fas fa-users" />,
       label: 'My Realtors',
       key: `${APP_PREFIX_PATH}/realtors`,
      },
     ]
   : []),
  ...(account_type === 'Realtor'
   ? [
      {
       icon: (
        <span>
         <MyBrokerageIcon />
        </span>
       ),
       label: 'My Brokerage',
       key: `${APP_PREFIX_PATH}/my-brokerage`,
      },
     ]
   : []),
  {
   icon: (
    <span>
     <SvgSettingsIcon />
    </span>
   ),
   key: `${APP_PREFIX_PATH}/settings`,
   label: 'Settings',
   children: [
    {
     label: 'Tags | Stage | Source',
     key: `${APP_PREFIX_PATH}/settings/tags-stages-sources`,
    },
    {
     label: 'Signature',
     key: `${APP_PREFIX_PATH}/settings/signature`,
    },
    {
     label: 'SMS Templates',
     key: `${APP_PREFIX_PATH}/settings/text-templates`,
    },
    {
     label: 'Email Templates',
     key: `${APP_PREFIX_PATH}/settings/email-templates`,
    },
    {
     label: 'Birthday Wishes',
     key: `${APP_PREFIX_PATH}/settings/birthday-wishes`,
    },
    ...(listing_key
     ? [
        {
         label: 'Listing Settings',
         key: `${APP_PREFIX_PATH}/settings/listing-settings`,
        },
       ]
     : []),
   ],
  },
  {
   label: 'Integration',
   key: `${APP_PREFIX_PATH}/integration`,
   icon: <i className="fas fa-link" />,
  },
  {
   icon: <i className="fas fa-chart-pie" />,
   key: `${APP_PREFIX_PATH}/reports`,
   label: 'Report',
   children: [
    {
     label: 'Email Reports',
     key: `${APP_PREFIX_PATH}/reports/email-reports`,
    },
    {
     label: 'SMS Reports',
     key: `${APP_PREFIX_PATH}/reports/sms-reports`,
    },
   ],
  },
  {
   icon: <i className="fas fa-solid fa-globe" />,
   key: 'manage-website',
   label: 'Manage Website',
  },
 ]

 return (
  <Layout className="admin-layout" style={{ minHeight: '100vh' }}>
   <Sidebar sidebarLInk={SidebarLinks} />
   <Layout className="crm-wrapper-area">
    <Navbar />
    <Content className="crm-content-area">
     <OverlayContextWrapper />
     <Outlet />
    </Content>
    <Footer />
   </Layout>

   {global.notification && (
    <NoticationModule
     visible={global.notification}
     onCancel={() => toggleNotification()}
    />
   )}
   <AdminControlPanel />
  </Layout>
 )
}

export default AdminLayout
