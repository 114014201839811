import { Form, Modal } from 'antd'
import useFetchPage from 'hooks/useFetchPage'
import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useMemo, useState } from 'react'
import { TModalState } from 'types'
import { MDataTable } from 'components/shared/MDataTable'
import useSelectAll from 'hooks/useSelectAll'
import { TAlertFeedType, TGetAlerts, TPutAlertType } from '../types'
import AlertBulkActionBlock from './AlertBulkActionBlock'
import AlertFormSection from './AlertFormSection'
import AlertTableColumns from './AlerttableColumns'

const AlertList: FC<{ type?: TAlertFeedType }> = ({ type = 'forsale' }) => {
 const [modalState, setModal] = useState<TModalState<TPutAlertType>>({
  show: false,
  mode: 'ADD',
  data: {
   fead: 'forsale',
  },
 })
 const [searchKey, setSearchKey] = useState('')
 const {
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  flatData,
  totalCount,
  isRefetching,
 } = useFetchPage<TGetAlerts>('get-alerts', {
  method: 'post',
  body: useMemo(() => ({ fead: [type], search: searchKey }), [type, searchKey]),
 })

 const {
  selectedKeys,
  onSelectRow,
  rowSelectionState,
  selectAll,
  unSelected,
  setSelectAll,
  setUnSelected,
  setSelectedRow,
 } = useSelectAll(JSON.stringify({ search: searchKey }))

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])

 useEffect(() => {
  setSelectAll(false)
  setUnSelected([])
  setSearchKey('')
  setSelectedRow([])
 }, [type])

 const [form] = Form.useForm<
  TPutAlertType & { priceRange?: { min?: any; max?: any } }
 >()

 const alertTitle = useMemo(() => {
  switch (type) {
   case 'forsale':
    return 'New Listing Alert'
   case 'sold':
    return 'Neighbourhood Alert'
   case 'openhouse':
    return 'Open House Alert'

   default:
    return ''
  }
 }, [type])

 return (
  <>
   <ListContentLayout
    title={alertTitle}
    actions={
     <AlertBulkActionBlock
      selectedKeys={selectedKeys}
      onClickAdd={() => setModal({ show: true, mode: 'ADD' })}
      alertRefresh={refetch}
      selectAll={selectAll}
      unSelected={unSelected}
      filterInfo={JSON.stringify({ search: searchKey, fead: type })}
      type={type}
     />
    }
   >
    <MDataTable
     totalData={totalCount || 1000}
     search={{
      placeholder: 'Enter Alert Name',
      onSearch: setSearchKey,
      show: true,
     }}
     onSelectRow={onSelectRow}
     rowSelectionState={{
      ...rowSelectionState,
     }}
     loading={isLoading}
     isFetching={isRefetching}
     hasMore={hasMore}
     fetchMore={() => {
      fetchMore()
     }}
     data={flatData}
     columns={AlertTableColumns({
      handleClickEdit: (val) =>
       setModal({ show: true, mode: 'EDIT', data: val }),
     })}
    />
   </ListContentLayout>
   {modalState.show && (
    <Modal
     footer={null}
     width={1100}
     title={`${modalState.mode === 'EDIT' ? 'Edit' : 'Add '} Alert`}
     onCancel={() => {
      setModal({ show: false })
      form.resetFields()
     }}
     visible={modalState.show}
    >
     {/* {modalState.show && ( */}
     <AlertFormSection
      show={modalState.show}
      form={form}
      type={type}
      mode={modalState.mode}
      editData={modalState.data as any}
      onCancel={() => {
       setModal({ show: false })
       form.resetFields()
      }}
      refresh={refetch}
     />
    </Modal>
   )}
  </>
 )
}

export default AlertList
