/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { useDispatch } from 'react-redux'
import { Form, Input } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { useSendListingMessageMutation } from 'api/baseApi'
import {
 TSendMailBody,
 useSendScheduleSmsLeadMutation,
 useSendScheduleSmsProMutation,
 useSendSmsLeadMutation,
} from 'features/leads'
import { useGetSmsTemplateByIdQuery } from 'features/settings'
import useAppControl from 'hooks/useAppControl'
import useAuth from 'hooks/useAuth'
import { FC, useEffect, useMemo, useState } from 'react'
import { globalToaster } from 'components/elements/global'
import SWToBar from 'components/elements/SWToBar/SWToBar'
import SWToBarTag from 'components/elements/SWToBarTag/SWToBarTag'
import { useGlobal } from 'raducer/global'
import { useUpdateTextScheduleMutation, inboxApi } from 'features/inbox'
import { queryClient } from 'lib/react-query'
import { SendWidgetProps } from '..'
import SendWidgetLayout from '../Layout'
import { SSWInputArea } from '../_components/SSWInputArea'
import { setProPreLoadContent } from '../functions'

const SmsSendWidget: FC<SendWidgetProps> = ({
 wFrom,
 type,
 onSendSuccess,
 mode,
}) => {
 const { user } = useAuth()
 const d = useDispatch()
 const initialValues: TSendMailBody = {
  to: [],
  from: user?.twilio_phone,
  body: '',
 }
 const [templateId, setTemplateId] = useState<any>()
 const {
  data: tempData,
  isLoading,
  isFetching,
 } = useGetSmsTemplateByIdQuery({ id: templateId }, { skip: !templateId })

 const { sendWidget, updateSendSMSWidget, onSuccessSMS } = useAppControl()
 const [sendSMSForm] = Form.useForm<TSendMailBody>()
 const {
  selectAll,
  unSelected,
  filterInfo,
  extraBody,
  showMoreLabel,
  propFullInfo,
  to,
  propertyData,
  proListingType,
  widgetFrom,
  customtSelectAll,
  editData,
  inboxRefetchKey,
 } = sendWidget || {}
 const { global } = useGlobal()

 const [scheduleSms, { isLoading: scheduleSmsLoading }] =
  useSendScheduleSmsLeadMutation()
 const [sendSMS, { isLoading: submitSendLading }] = useSendSmsLeadMutation()
 const [SendScheduleSmsPro, { isLoading: SendScheduleSmsProLoading }] =
  useSendScheduleSmsProMutation()
 const [sendListingMessage, { isLoading: listingSmsLoading }] =
  useSendListingMessageMutation()
 const [updateTextSchedule, { isLoading: updateTextScheduleLoading }] =
  useUpdateTextScheduleMutation()

 const watch = Form.useWatch([], sendSMSForm)
 const productCount = useMemo(() => propFullInfo?.length || 0, [propFullInfo])

 const valueToField = useMemo(() => {
  if (type === 'tag') {
   return (watch?.to as any)?.map?.((i: any) => i?.id)?.join(',')
  }
  if (Array.isArray(watch?.to)) {
   return watch?.to?.join(',')
  }
  return watch?.to
 }, [widgetFrom, mode, watch])

 const isSelectAll = useMemo(() => {
  if (customtSelectAll !== undefined) {
   return customtSelectAll !== null
  }
  return selectAll ?? false
 }, [customtSelectAll, selectAll])

 const handleSubmitSend = async (time?: any, date?: any): Promise<void> => {
  const { to: toF, ...values } = await sendSMSForm.validateFields()

  if (isSelectAll !== true && toF?.length === 0) {
   globalToaster({
    type: 'error',
    content: 'Add at least one recipient',
   })
  } else if (mode === 'EDIT-SHEDULE-SMS') {
   const formData = new FormData()

   const removedTo = (editData?.to as any)?.filter(
    (i: any) => !toF?.includes(i),
   )

   const newTo = (toF as any)?.filter(
    (item: any) => !editData?.to?.includes(item),
   )

   if ((removedTo?.length ?? 0) > 0) {
    formData.append('removed_to', removedTo)
   }

   if ((newTo?.length ?? 0) > 0) {
    formData.append('new_to', newTo)
   }

   formData.append('date', date)
   formData.append('time', time)
   formData.append('from', user?.twilio_phone ?? '')
   formData.append('body', values?.body ?? '')
   if (templateId) {
    formData.append('temp_id', templateId)
   }

   await updateTextSchedule({ id: editData?.id, data: formData }).unwrap()
   setTemplateId(undefined)
   d(inboxApi.util.invalidateTags(['SCHEDULE-SMS-PREVIEW']))
   if (inboxRefetchKey) {
    queryClient.invalidateQueries(inboxRefetchKey)
   }
   onSuccessSMS?.()
   onSendSuccess?.()
  } else {
   const postData: TSendMailBody = {
    ...values,
    ...(!isSelectAll ||
    (selectAll && !showMoreLabel) ||
    (isSelectAll && !showMoreLabel)
     ? {
        to: valueToField,
       }
     : {}),
    type,
    ...(propFullInfo && propFullInfo?.length > 0
     ? {
        property:
         propFullInfo?.map?.((i: any, n) => ({
          id: i?.slug,
          type: i?.listing_type,
         })) ?? [],
        listing_type: proListingType,
       }
     : {}),
    ...(templateId ? { temp_id: templateId } : {}),
    ...(date || time ? { date, time } : {}),
    ...(propFullInfo ? { prop_full_info: JSON.stringify(propFullInfo) } : {}),
    ...(selectAll && showMoreLabel ? { select_all: selectAll } : {}),
    ...(customtSelectAll && showMoreLabel ? { customtSelectAll } : {}),
    ...(unSelected && isSelectAll && showMoreLabel
     ? { unselected: unSelected }
     : {}),
    ...(filterInfo && isSelectAll && showMoreLabel
     ? { filter_info: filterInfo }
     : {}),
    ...extraBody,
   }

   if (propFullInfo && propFullInfo?.length > 0) {
    time || date
     ? await SendScheduleSmsPro(postData).unwrap()
     : await sendListingMessage(postData).unwrap()
   } else {
    time || date
     ? await scheduleSms(postData).unwrap()
     : await sendSMS(postData).unwrap()
   }
   setTemplateId(undefined)
   onSuccessSMS?.()
   onSendSuccess?.()
  }
 }

 useEffect(() => {
  if (editData && mode === 'EDIT-SHEDULE-SMS') {
   sendSMSForm.setFieldsValue({
    body: editData?.body ?? '',
   })
   if (editData?.temp_id) {
    setTemplateId(editData?.temp_id)
   }
  }
 }, [editData])

 useEffect(() => {
  if (tempData?.body) {
   sendSMSForm.setFieldsValue({
    body: tempData.body ?? '',
   })
  }
 }, [tempData?.body])

 useEffect(() => {
  if (to) {
   sendSMSForm.setFieldsValue({
    to,
   })
  }
 }, [to])

 useEffect(() => {
  if (widgetFrom === 'listing' && (propFullInfo?.length ?? 0) > 0) {
   sendSMSForm.setFieldsValue({
    body: setProPreLoadContent({
     conte: global?.listing_sms_content,
     single: propFullInfo?.length === 1,
     type: proListingType,
    }),
   })
  }
 }, [sendWidget?.propFullInfo])

 //  useEffect(() => {
 //   if (widgetFrom === 'listing' && proListingType === 'preconstruction') {
 //    sendSMSForm.setFieldsValue({
 //     body:
 //      (propFullInfo?.length ?? 0) > 1
 //       ? global?.listing_sms_content?.preconstruction_multiple
 //       : global?.listing_sms_content?.preconstruction_single,
 //    })
 //   }
 //  }, [proListingType, propFullInfo])

 const ToField = useMemo(() => {
  if (type === 'tag') {
   return <SWToBarTag type="text" />
  }
  return <SWToBar type="phoneNumber" />
 }, [type, to])

 const toFieldValue = useMemo(() => {
  if (type === 'lead') {
   return Array.isArray(watch?.to)
    ? { to: watch?.to?.join(',') ?? [] }
    : { to: watch?.to }
  }
  return {
   to: (watch?.to as [])?.map((i: any) => i?.id)?.join(',') ?? [],
  }
 }, [type, watch])

 return (
  <SendWidgetLayout
   proData={sendWidget?.propFullInfo}
   onUpdateProData={(val) => updateSendSMSWidget({ propFullInfo: val })}
   submitLoading={
    submitSendLading ||
    listingSmsLoading ||
    updateTextScheduleLoading ||
    scheduleSmsLoading ||
    SendScheduleSmsProLoading
   }
   mode={mode}
   onSend={() => handleSubmitSend()}
   scheduleLoading={
    scheduleSmsLoading || SendScheduleSmsProLoading || updateTextScheduleLoading
   }
   onSchedule={(time, date) => handleSubmitSend(time, date)}
   productCount={productCount}
   previewData={{
    // ...(type === 'lead'
    //  ? {
    //     to: Array.isArray(watch?.to) ? watch?.to?.join(',') : watch?.to,
    //    }
    //  : {
    //     to: Array.isArray(watch?.to)
    //      ? watch?.to?.map?.((i: any) => i.id)?.join(',')
    //      : watch?.to,
    //    }),
    ...toFieldValue,
    from: user?.twilio_phone,
    body: watch?.body,
    type,
    ...(widgetFrom === 'listing'
     ? {
        property: JSON.stringify(
         propFullInfo?.map((i) => ({
          id: i?.slug,
          type: i?.listing_type,
         })),
        ),
        listing_type: proListingType,
       }
     : {}),
    ...(propFullInfo && widgetFrom === 'listing'
     ? { prop_full_info: propFullInfo }
     : {}),
    ...(proListingType === 'preconstruction' && widgetFrom === 'listing'
     ? { listing_type: 'preconstruction' }
     : {}),
   }}
   loading={isLoading || isFetching}
   from="SMS"
   handleValidation={() => {
    if (
     !toFieldValue?.to ||
     (toFieldValue?.to && toFieldValue?.to.length === 0)
    ) {
     globalToaster({ type: 'error', content: 'Enter To Input' })
     return false
    }
    return true
   }}
   editData={editData}
  >
   <div className="sw-form-section">
    <Form initialValues={initialValues} form={sendSMSForm}>
     <FormItem label="From" name="from">
      <Input readOnly />
     </FormItem>
     <FormItem name="to" label="To">
      {ToField}
     </FormItem>
     <FormItem name="body" className="sw-texteditor">
      <SSWInputArea
       templateId={templateId}
       onSelectTemp={setTemplateId}
       template={!(propFullInfo && (propFullInfo?.length ?? 0) > 0)}
      />
     </FormItem>
    </Form>
   </div>
  </SendWidgetLayout>
 )
}

export default SmsSendWidget
