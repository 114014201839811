import { Col, Form, Input, InputNumber, Row, Select, Space } from 'antd'
import {
 useGetCityListQuery,
 useGetCityV1Query,
 useGetCityV2Query,
} from 'api/baseApi'

import { BaseButton } from 'components/elements/BaseButton'
import PriceRangerDropDown from 'components/shared/Property/PriceRangerDropDown'
import useAuth from 'hooks/useAuth'
import React, { FC, useEffect, useState } from 'react'
import { BathList, BedList, Frequencys } from 'static-data'
import { TMode } from 'types'
import { FormInstance } from 'antd/es/form/Form'
import {
 useAddNewAlertMutation,
 useEditNewAlertMutation,
 useGetAlertFiltersQuery,
} from '../api'
import { TAlertFeedType, TPutAlertType } from '../types'

const formInit: TPutAlertType & { priceRange?: { min?: any; max?: any } } = {
 name: '',
 area: [],
 frequency: 'Daily',
 bed: undefined,
 bath: undefined,
 min_price: 0,
 max_price: 5000000,
 count: 1,
 property_sub_type: [],
 property_status: [],
 fead: 'forsale',
 priceRange: { min: 0, max: 5000000 },
}

const AlertFormSection: FC<{
 show?: boolean
 mode?: TMode
 type?: TAlertFeedType
 editData?: TPutAlertType
 onCancel?: () => void
 refresh?: () => void
 form: FormInstance<
  TPutAlertType & {
   priceRange?:
    | {
       min?: any
       max?: any
      }
    | undefined
  }
 >
 isReadOnly?: boolean
}> = ({
 mode = 'ADD',
 type = 'forsale',
 editData,
 onCancel,
 refresh,
 form,
 show,
 isReadOnly = false,
}) => {
 const [showEdit, setShowEdit] = useState(show)

 const { data: v1Data } = useGetCityV1Query()

 const { data: v2Data } = useGetCityV2Query()
 const { data: cityList } = useGetCityListQuery()

 const [addAlert, { isLoading: addLoading }] = useAddNewAlertMutation()

 const { user } = useAuth()

 const [editAlert, { isLoading: editLOading }] = useEditNewAlertMutation()
 const [alertfilterQuery, setAlertFilterQuery] = useState('')
 const [alertFilterBuildingType, setAlertFilterBuildingType] = useState<any>([])

 useEffect(() => {
  setAlertFilterQuery(
   `${
    alertFilterBuildingType?.length !== 0
     ? `?type=${JSON.stringify(alertFilterBuildingType)}`
     : ''
   }`,
  )
  form.setFieldsValue({ property_sub_type: [], property_status: [] })
 }, [alertFilterBuildingType])

 const { data: alertFilters } = useGetAlertFiltersQuery({
  val: alertfilterQuery,
 })

 const handleSubmitForm = async () => {
  const values = await form.validateFields()

  const finalValues: any = values

  finalValues.min_price = values.priceRange?.min
  finalValues.max_price = values.priceRange?.max
  finalValues.fead = type
  finalValues.id = editData?.id

  finalValues.property_status =
   values?.property_status && values?.property_status?.length > 0
    ? values?.property_status?.toString().split(',')
    : null

  finalValues.property_sub_type =
   values?.property_sub_type && values?.property_sub_type?.length > 0
    ? values?.property_sub_type?.toString().split(',')
    : null

  delete finalValues.priceRange

  if (mode === 'EDIT') {
   handleUpdateForm(finalValues)
  } else {
   handleAddForm(finalValues)
  }
 }

 const handleUpdateForm = async (val: any) => {
  await editAlert(val).unwrap()
  refresh?.()
  form.resetFields()
  setAlertFilterBuildingType([])

  setShowEdit(!showEdit)
  onCancel?.()
 }

 const handleAddForm = async (val?: any) => {
  await addAlert(val).unwrap()
  refresh?.()
  form.resetFields()
  setAlertFilterBuildingType([])

  setShowEdit(!showEdit)

  onCancel?.()
 }

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   setAlertFilterQuery(
    `?type=${JSON.stringify(editData?.type?.toString()?.split(',')) ?? ''}`,
   )
   form.setFieldsValue({
    name: editData.name,
    area: editData.area,
    property_sub_type: editData.property_sub_type?.toString().split(','),
    property_status:
     type === 'sold' ? [] : editData.property_status?.toString().split(','),
    frequency: editData.frequency,
    count: editData.count,
    bed: editData.bed,
    bath: editData.bath,
    type: editData.type?.toString().split(','),
    priceRange: { min: editData?.min_price, max: editData?.max_price },
   })
  }
  // else {
  //  form.resetFields()
  // }
  // return () => {
  //  form.resetFields()
  // }
 }, [editData, form, mode, showEdit])

 return (
  <Form
   form={form}
   initialValues={formInit}
   layout="vertical"
   className={`alert-form ${isReadOnly ? 'disabled-form' : ''}`}
  >
   <Row gutter={[15, 15]}>
    <Col span={12}>
     <Form.Item
      className="mb-0"
      label="Name"
      name="name"
      rules={[{ required: true, message: 'Name is required' }]}
     >
      <Input placeholder="Enter name" readOnly={isReadOnly} />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item
      className="mb-0"
      label="Area"
      name="area"
      rules={[{ required: true, message: 'Area is required' }]}
     >
      <Select
       placeholder="Search city..."
       className="w-100"
       mode="multiple"
       //  maxTagCount={2}
       disabled={isReadOnly}
       options={
        user?.api_version === 'v1'
         ? v1Data?.data?.map((item) => ({
            value: item,
            label: item,
           }))
         : user?.api_version === 'v2'
         ? v2Data?.data?.map((item) => ({
            value: item,
            label: item,
           }))
         : cityList?.map((item) => ({
            value: item,
            label: item,
           }))
       }
      />
     </Form.Item>
    </Col>
    {alertFilters?.listing_type && (
     <Col span={12}>
      <Form.Item label="Listing type" className="mb-0" name="type">
       <Select
        placeholder="select Listing type"
        mode="multiple"
        options={alertFilters?.listing_type?.map((j, k) => ({
         label: j,
         value: j,
        }))}
        onSelect={(e: any) => {
         setAlertFilterBuildingType?.([...alertFilterBuildingType, e])
        }}
        onDeselect={(e: any) => {
         setAlertFilterBuildingType((prv: any) => {
          const filtered = prv.filter((v: any) => v !== e)
          return [...filtered]
         })
        }}
        disabled={isReadOnly}
       />
      </Form.Item>
     </Col>
    )}
    {alertFilters?.building_type && (
     <Col span={12}>
      <Form.Item
       className="mb-0"
       label="Building type"
       name="property_sub_type"
      >
       <Select
        disabled={isReadOnly}
        placeholder="Select Property type"
        className="w-100"
        options={alertFilters?.building_type?.map((j, k) => ({
         label: j,
         value: j,
        }))}
        mode="multiple"
       />
      </Form.Item>
     </Col>
    )}
    {alertFilters?.listing_status && type !== 'sold' && (
     <Col span={12}>
      <Form.Item className="mb-0" label="Listing Status" name="property_status">
       <Select
        disabled={isReadOnly}
        placeholder="Select Listing Status"
        className="w-100"
        options={alertFilters?.listing_status?.map((j, k) => ({
         label: j,
         value: j,
        }))}
       />
      </Form.Item>
     </Col>
    )}

    <Col span={12}>
     <Form.Item className="mb-0" label="Frequency" name="frequency">
      <Select
       disabled={isReadOnly}
       placeholder="Select Frequency"
       className="w-100"
       options={Frequencys}
       defaultValue="Daily"
      />
     </Form.Item>
    </Col>
    <Col span={8}>
     <Form.Item className="mb-0" label="No of properties to send" name="count">
      <InputNumber
       min={1}
       placeholder="Enter No of properties to send"
       className="w-100"
       type="number"
       readOnly={isReadOnly}
      />
     </Form.Item>
    </Col>
    <Col span={8}>
     <Form.Item className="mb-0" label="Bedrooms" name="bed">
      <Select
       disabled={isReadOnly}
       options={BedList}
       placeholder="Select Bedrooms"
       className="w-100"
      />
     </Form.Item>
    </Col>
    <Col span={8}>
     <Form.Item className="mb-0" label="Bathrooms" name="bath">
      <Select
       disabled={isReadOnly}
       options={BathList}
       placeholder="Select Bathrooms"
       className="w-100"
      />
     </Form.Item>
    </Col>
    <Col span={8}>
     <Form.Item className="mb-0" label="Price Range" name="priceRange">
      <PriceRangerDropDown isReadOnly={isReadOnly} />
     </Form.Item>
    </Col>
    {!isReadOnly && (
     <Col span={24}>
      <Space>
       <BaseButton
        buttonType="danger"
        onClick={() => {
         setShowEdit(!showEdit)
         onCancel?.()
        }}
       >
        Cancel
       </BaseButton>
       <BaseButton
        buttonType="primary"
        onClick={handleSubmitForm}
        loading={mode === 'EDIT' ? editLOading : addLoading}
       >
        {mode === 'EDIT' ? 'Update' : 'Submit'}
       </BaseButton>
      </Space>
     </Col>
    )}
   </Row>
  </Form>
 )
}

export default AlertFormSection
